/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100;500&family=Poppins&family=Raleway:ital,wght@0,100;0,200;0,600;1,200;1,600&display=swap');

* {
    font-family: 'Poppins', sans-serif !important;
}

.text-danger {
    color: red;
    font-size: 14px;
    margin-top: 4px !important;
    margin-left: 2px !important;
}

.text-ellipses {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PermissionIcon {
    background-color: #1877F2;
    border-radius: 50%;
    padding: 10px 6px;
    margin: 20px 0px;
}

.rs-play {
    background-color: #fff !important;
}

.rs-container {
    width: auto !important;
}

.listMedia iframe {
    border-radius: 6px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.listMedia video {
    border-radius: 6px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
    object-fit: cover;
}

.toggleBtn {
    cursor: pointer;
    pointer-events: all;
    outline: none;
    background-color: #fff;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 6px;
}

.interviewScreen video {
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #ccc;
}

.Btn-group {
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    background-color: #212B36;
    border-radius: 6px;
}

input:focus-visible {
    border: none;
    outline: none;
}

.hover-card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* upoad  */
.dragBox {
    max-width: 700px;
    width: 100%;
    margin: 20px auto;
    position: relative;
    text-align: center;
    color: #888;
    border: 4px dashed #f2f2f2;
    display: inline-block;
    transition: transform 0.3s;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
    padding-top: 12px;
    line-height: 18px;
    letter-spacing: 0.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dragBox input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
}

.uploadicon {
    background-color: #1c77f238;
    padding: 6px;
    border-radius: 8px;
    margin-right: 10px;
}

.css-kzwz4p-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    border: 0.2px solid #1c77f238 !important;
    border-radius: 5px !important;
}

.customBadge {
    background: #1c77f238;
    padding: 1px 4px;
    font-size: 12px;
    color: #1877F2;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.MuiSelect-select {
    text-align: left !important;
}

.ssss {
    font-weight: 100;
    color: #1877F2;
    background-image: -webkit-linear-gradient(92deg, #1877F2, #093c94);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: hue 10s infinite linear;
}

@-webkit-keyframes hue {
    from {
        -webkit-filter: hue-rotate(0deg);
    }

    to {
        -webkit-filter: hue-rotate(-360deg);
    }
}


.read-more-container {
    max-height: 200px;
    /* Height of 4 lines */
    overflow: hidden;
    position: relative;
}

.text {
    transition: max-height 0.5s ease;
    overflow: hidden;
}

.text.expanded {
    max-height: none;
}

.read-more-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.read-more-button:hover {
    background-color: #0056b3;
}

.read-more-button.animating {
    pointer-events: none;
    opacity: 0;
    transform: translateY(20px);
}

.card-s {
    transition: all ease 0.7s;
    object-fit: cover;
}

.small-s {
    width: 150px;
    height: 120px;
    object-fit: cover;
    left: 10px;
    border-radius: 10px;
    top: 10px;
    position: absolute;
    z-index: 99;
}

.large-s {
    left: 0px;
    top: 0px;
    transform: none;
    width: 100%;
    max-height: 400px;
    position: absolute;
    z-index: 99;
}

.large-ss {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 480px;
    width: 640px;
    transform: none;
    position: absolute;
    z-index: 99;
}

input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: #000 !important;
    border-color: #ccc !important;
}

input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: #000 !important;
}

input:-webkit-autofill:hover {
    box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: #000 !important;
}
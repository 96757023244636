.inner-container {
  display: flex;
  gap: 5rem;
  /* width: 100%;
  max-width: 320px; */
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1024px) {
  .inner-container {
    gap: 2.5rem;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.jEnd {
  justify-content: end;
}

.btnGrp {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;
}
.contact-card {
  background: white;
  /* max-width: 450px; */
  border-radius: 20px;
  padding: 2rem;
}
.leftDiv{
  width: 50%;
}

.rightDiv{
  width: 50%;
}
.pretext{
  margin-top: 1rem;
  gap: 1rem;
  flex-direction: column;
}

.contact-container {
  padding: 60px 20px  !important;

}

.override{
  max-width: 100% !important;
}

.modal-img{
  width: 75%;
}

@media (min-width:450px) {
  .modal-img{
      max-width: 40%;
  }
  
}

@media (max-width: 484px){
  .contact-card{
    padding: 1rem;
  }
  .contact-container{
    padding: 1rem !important;
  }
}

@media (min-width: 555px) {
  .innerContent{
    max-width: 70% !important;
  }
  
}

@media (max-width: 767px) {
  .inner-container {
    flex-direction: column;
    gap: 1rem;
  }
  .btnGrp {
    /* flex-direction: column-reverse; */
    align-items: center;
    /* margin-top: 1rem; */
    gap: 1rem;
  }
  .contact-card{
    flex-direction: column;
  }
  .leftDiv{
    width: 100%;
  }
  .rightDiv{
    width: 100%;
  }
}

@media (max-width: 915px){
  .ttCol {
    flex-direction: column !important;
    gap: 1rem;
  }
}

@media (max-width: 600px) {
  .responsive-para{
    font-size: 13px;
  }
  
}
@media (min-width: 600px) {
  .responsive-para{
    font-size: 15px;
  }
  
}




/* loaderinternal */
.loaderss {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 75px 75px 20px 75px;
    display: inline-block;
    vertical-align: middle;
}

.loader-1 .loader-outter {
    position: absolute;
    border: 4px solid #1877f2;
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loader-1 .loader-inner {
    position: absolute;
    border: 4px solid #1877f2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border-right: 0;
    border-top-color: transparent;
    -webkit-animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
    animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loader-1 .inner {
    position: absolute;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 9px);
    border-right: 0;
    border-top-color: transparent;
    font-size: 12px;
    color: #1877f2;
    font-weight: 600;
}

@-webkit-keyframes loader-1-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-1-outter {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader-1-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes loader-1-inner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    /* make things invisible upon start */
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 1.4s;
}
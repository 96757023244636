/* Pricing.css */

.pricing-page {
  /* margin-top: 100px !important; */
  padding: 4rem 2rem !important;
  max-width: 1200px !important;
  margin: 50px auto !important;
  background-color: #fafafa !important;
}

.header-section {
  text-align: center !important;
  margin-bottom: 4rem !important;
}

.header-title {
  font-size: 2.5rem !important;
  font-weight: 700 !important;
  margin-bottom: 1.5rem !important;
  color: #2c3e50 !important;
}

.header-description {
  font-size: 1.2rem !important;
  color: #5a6a7a !important;
  max-width: 700px !important;
  margin: 0 auto !important;
  line-height: 1.6 !important;
}

.highlight {
  color: #1976d2 !important;
  font-weight: 600 !important;
}

.pricing-cards {
  margin-bottom: 4rem !important;
}

.pricing-card {
  height: 100% !important;
  transition: transform 0.2s ease-in-out !important;
  border: 1px solid #e0e0e0 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.pricing-card:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15) !important;
}

.card-icon {
  font-size: 2rem !important;
  color: #1976d2 !important;
}

.subtext {
  font-size: 1rem !important;
  color: #666 !important;
  font-weight: normal !important;
}

.feature-list {
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.feature-item {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 1rem !important;
  color: #4a4a4a !important;
}

.check-icon {
  color: #4caf50 !important;
  margin-right: 0.75rem !important;
  font-size: 1.2rem !important;
}

.features-section,
.steps-section {
  margin: 4rem 0 !important;
}

.section-title {
  text-align: center !important;
  margin-bottom: 2rem !important;
  color: #2c3e50 !important;
  font-weight: 600 !important;
}

.feature-box,
.step-box {
  display: flex !important;
  /* align-items: flex-start !important; */
  padding: 1.5rem !important;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
  height: 100% !important;
}

.step-box {
  flex-direction: column !important;
  text-align: center !important;
}

.step-number {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background-color: #1976d2 !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto 1rem !important;
}

.contact-section {
  text-align: center !important;
  margin-top: 4rem !important;
  padding: 2rem !important;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

.contact-text {
  margin-bottom: 0.5rem !important;
  color: #5a6a7a !important;
}

.contact-link {
  color: #1976d2 !important;
  text-decoration: none !important;
  font-weight: 500 !important;
}

.contact-link:hover {
  text-decoration: underline !important;
}

@media (max-width: 960px) {
  .pricing-page {
    padding: 2rem 1rem !important;
  }

  .header-title {
    font-size: 2rem !important;
  }

  .feature-box,
  .step-box {
    margin-bottom: 1rem !important;
  }
}

@media (max-width: 600px) {
  .header-title {
    font-size: 1.75rem !important;
  }

  .header-description {
    font-size: 1rem !important;
  }

  .pricing-card {
    margin-bottom: 1.5rem !important;
  }
}
